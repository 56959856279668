import React from 'react';
import BookIcon from 'assets/icons/Books.png';
import PageIcon from 'assets/icons/Page.png';
import UserIcon from 'assets/icons/Profile.png';
import CollectionIcon from 'assets/icons/Collections.png';
import ObservationIcon from 'assets/icons/Observations.png';
import TaxonomyIcon from 'assets/icons/Taxa.png';

const IconComponent = ({ text, size = 75 }) => {
    const iconMap = {
        BookSearchDocument: BookIcon,
        BookPageSearchDocument: PageIcon,
        UserSearchDocument: UserIcon,
        CollectionSearchDocument: CollectionIcon,
        ObservationSearchDocument: ObservationIcon,
        TaxonomySearchDocument: TaxonomyIcon
    };

    const iconSrc = iconMap[text] || null;

    return (
        <div
            style={{
                width: size,
                height: size,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                overflow: 'hidden',
            }}
        >
            {iconSrc ? (
                <img
                    src={iconSrc}
                    alt={`${text} icon`}
                    style={{
                        maxWidth: '100%',
                        maxHeight: '100%',
                        objectFit: 'contain',
                    }}
                />
            ) : (
                <span>No icon found</span>
            )}
        </div>
    );
};

export default IconComponent;
